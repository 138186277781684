<template>
  <b-card :title="titleTable">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <!-- <b-form-input
            v-model="searchTerm"
            placeholder="Buscar..."
            type="text"
            class="d-inline-block"
          /> -->
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="outline-primary">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTerm"
              placeholder="Buscar..."
            />
          </b-input-group>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :is-loading.sync="isLoadingActive"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :select-options="{
        enabled: selectRow,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'registro seleccionado',
        clearSelectionText: 'Limpiar',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :line-numbers="lineNumber"
      :pagination-options="{
        enabled: true,
        position: 'both',
        perPage:pageLength
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="emptystate">
        No existen registros
      </div>
      <div slot="table-actions">
        <b-button-toolbar
          justify
        >
          <p class="mx-1">
            <strong>Acciones:</strong>
          </p>
          <b-button-group>
            <b-button
              v-if="is_active_create"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="Crear"
            >
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
            <b-button
              v-if="is_active_edit"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="Edit_one"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="is_active_document_select"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="ActivateDocumentSelect"
            >
              <feather-icon icon="PaperclipIcon" />
            </b-button>
            <b-button
              v-if="is_active_messsage"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="SendMessage"
            >
              <feather-icon icon="MessageCircleIcon" />
            </b-button>
            <b-button
              v-if="is_active_trash_all"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="DeleteAll"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button
              v-if="is_active_trash_all_back"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="DeleteAllBack"
            >
              <feather-icon icon="ThumbsUpIcon" />
            </b-button>
            <b-button
              v-if="is_active_course_all"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="ActivateCourseAll"
            >
              <feather-icon icon="BookmarkIcon" />
            </b-button>
            <b-button
              v-if="is_active_course_all"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="ActivateCourseAllDeactivate"
            >
              <feather-icon icon="BookmarkIcon" />
            </b-button>
            <b-button
              v-if="is_active_notify_all"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="SendNotifyAll"
            >
              <feather-icon icon="BellIcon" />
            </b-button>
            <b-button
              v-if="is_active_show_data_all"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="DocumentShow"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="is_change_status_documento"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="ChangeStatusDocumentSelect"
            >
              <feather-icon icon="PaperclipIcon" />
            </b-button>
            <b-button
              v-if="is_change_status_documento_traducido"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="ChangeStatusDocumentTraductorSelect"
            >
              <feather-icon icon="BookIcon" />
            </b-button>
            <b-button
              v-if="is_active_exportar"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="Exportar"
            >
              <feather-icon icon="ArrowDownCircleIcon" />
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>
      <template
        slot="column-filter"
        slot-scope="{ column, updateFilters }"
      >
        <flat-pickr
          v-if="column.field === 'startDate'"
          v-model="dateDefault"
          :config="config"
          class="form-control"
          placeholder="Buscar fecha"
          @input="(dateDefault) => updateFilters(column, dateDefault)"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'profile.fullname'"
          class="text-nowrap"
        >
          <b-avatar
            :src="setAvatar(props.row.profile)"
          />
        </span>

        <span
          v-if="props.column.field === 'contrato.n_contrato'"
          class="text-nowrap"
        >
          <p v-if="props.row.contrato" />
          <p v-else>SIN CONTRATO</p>
        </span>
        <template
          v-if="props.column.field === 'ExampleDocument.archivo_example'"
          class="text-nowrap"
        >
          <b-button
            v-if="props.row.ExampleDocument && props.row.ExampleDocument.archivo"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="'Ver archivo'"
            variant="outline-primary"
            class="btn-icon rounded-circle mr-05"
            @click="ShowArchive(props.row)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
        <template
          v-if="props.column.field === 'archivo_normal'"
          class="text-nowrap"
        >
          <b-button
            v-if="props.row && props.row.archivo"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="'Ver archivo'"
            variant="outline-primary"
            class="btn-icon rounded-circle mr-05"
            @click="ShowArchiveDocument(props.row)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
        <template
          v-if="props.column.field === 'documento_traducido.archivo_traducir'"
          class="text-nowrap"
        >
          <b-button
            v-if="props.row.documento_traducido && props.row.documento_traducido.archivo"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="'Ver archivo'"
            variant="outline-primary"
            class="btn-icon rounded-circle mr-05"
            @click="ShowArchiveDocumentTraducido(props.row)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <span
            v-else
          >
            SIN TRADUCCIÓN
          </span>
        </template>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            pill
            :variant="statusVariant(props.row.status)"
          >
            {{ props.row.status }}
          </b-badge>
        </span>
        <!-- Column: Roles -->
        <span v-else-if="props.column.field === 'roles'">
          {{ props.row.roles[0].name }}
        </span>
        <!-- Column: fecha_contrato -->
        <span v-else-if="props.column.field === 'fecha_contrato'">
          <template v-if="props.row.contrato">
            {{ FormatDate(props.row.contrato.fecha_contrato) }}
          </template>
        </span>
        <!-- Column: Pricing -->
        <span v-else-if="props.column.field === 'total_price'">
          <template v-if="props.row.contrato">
            ${{ formatNumber(props.row.contrato.total_price) }}
          </template>
        </span>
        <span v-else-if="props.column.field === 'responsable'">
          <a
            v-if="props.row.responsable"
          >
            <b-badge
              pill
              variant="success"
            >
              Responsable
            </b-badge>
          </a>
          <a
            v-else
          >
            <b-badge
              pill
              variant="danger"
            >
              No responsable
            </b-badge>
          </a>
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <a
            v-if="props.row.is_active"
            @click="activeRegister(props.row)"
          >
            <b-badge
              pill
              variant="success"
            >
              Activo
            </b-badge>
          </a>
          <a
            v-else
            @click="activeRegister(props.row)"
          >
            <b-badge
              pill
              variant="danger"
            >
              Desactivado
            </b-badge>
          </a>
        </span>
        <span v-else-if="props.column.field === 'status.name'">
          <b-badge
            pill
            :variant="getStatus(props.row.status.name)"
          >
            {{ props.row.status.name }}
          </b-badge>
        </span>
        <span v-else-if="props.row.documento_traducido && props.column.field === 'documento_traducido.status.name' ">
          <b-badge
            pill
            :variant="getStatus(props.row.documento_traducido.status.name)"
          >
            {{ props.row.documento_traducido.status.name }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'is_translator'">
          <a
            v-if="props.row.is_translator"
            @click="translatorRegister(props.row)"
          >
            <b-badge
              pill
              variant="success"
            >
              Activo
            </b-badge>
          </a>
          <a
            v-else
            @click="translatorRegister(props.row)"
          >
            <b-badge
              pill
              variant="danger"
            >
              Desactivado
            </b-badge>
          </a>
        </span>

        <!-- Column: Action -->
        <template v-else-if="props.column.field === 'action'">
          <span>
            <b-button
              v-if="is_active_edit"
              v-b-tooltip.hover.top="'Editar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="Edit(props.row)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              v-if="is_active_trash"
              v-b-tooltip.hover.top="'Eliminar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="Delete(props.row)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button
              v-if="is_active_trash_back"
              v-b-tooltip.hover.top="'Restaurar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="DeleteBack(props.row)"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              v-if="is_active_detail"
              v-b-tooltip.hover.top="'Ver registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="Detail(props.row)"
            >
              <feather-icon icon="LayersIcon" />
            </b-button>
            <b-button
              v-if="is_active_course"
              v-b-tooltip.hover.top="'Activar curso'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="ActivateCourse(props.row)"
            >
              <feather-icon icon="BookmarkIcon" />
            </b-button>
            <b-button
              v-if="is_active_family"
              v-b-tooltip.hover.top="'Acudientes'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="ActivateFamily(props.row)"
            >
              <feather-icon icon="UsersIcon" />
            </b-button>
            <b-button
              v-if="is_active_document"
              v-b-tooltip.hover.top="'Documentos del estudiante'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="ActivateDocument(props.row)"
            >
              <feather-icon icon="PaperclipIcon" />
            </b-button>
            <b-button
              v-if="is_active_contrato"
              v-b-tooltip.hover.top="'Generar contrato'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="OpcionContrato(props.row)"
            >
              <feather-icon icon="LinkIcon" />
            </b-button>
            <b-button
              v-if="is_active_notificar"
              v-b-tooltip.hover.top="'Notificaciones'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="SendNotify(props.row)"
            >
              <feather-icon icon="BellIcon" />
            </b-button>
            <template v-if="is_active_responsable">
              <template
                v-if="props.row.responsable == 0 || props.row.responsable == null"
              >
                <b-button
                  v-b-tooltip.hover.top="'Colocar como responsable de contrato'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat"
                  class="btn-icon "
                  @click="activeResponsable(props.row)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </template>
              <template
                v-else
              >
                <b-button
                  v-b-tooltip.hover.top="'Quitar responsable de contrato'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat"
                  class="btn-icon "
                  @click="activeResponsable(props.row)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </template>
            </template>
          </span>
        </template>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-top"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap b-b">
          <div class="d-flex align-items-center mb-02 mt-1 ml-1">
            <span class="text-nowrap">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registro </span>
          </div>
          <div>
            <b-pagination
              v-model="valuePagination"
              :value="valuePagination"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0 mr-1"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1 ml-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registro </span>
          </div>
          <div>
            <b-pagination
              v-model="valuePagination"
              :value="valuePagination"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0 mr-1"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { FormatDate } from '@core/utils/filter'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BCard, BButtonToolbar, BButtonGroup, BTooltip, VBTooltip, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
/* eslint-disable */
import { Spanish } from 'flatpickr/dist/l10n/es.js'
// import { codeBasic } from './code'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    // BCardCode,
    BTooltip,
    BButtonToolbar,
    BButtonGroup,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    flatPickr,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
  },
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    rows: {
      type: Array,
      required: true,
      default: () => [],
    },
    advanceSearch: {
      type: String,
      required: false,
      default: () => '',
    },
    selectRow: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    titleTable: {
      type: String,
      required: false,
      default: () => '',
    },
    lineNumber: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_active_create: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_document_select: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_edit: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_trash: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_trash_back: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_detail: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_course: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_exportar: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_messsage: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_course_all: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_notify_all: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_show_data_all: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_family: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_contrato: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_notificar: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_responsable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_trash_all: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_trash_all_back: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_active_document: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_change_status_documento: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    is_change_status_documento_traducido: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup() {
    return {
      FormatDate,
    }
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      // codeBasic,
      dateDefault: null,
      valuePagination: 1,
      config: {
        locale: Spanish,
        altFormat: 'M j, Y',
        dateFormat: 'm/d/Y',
      },
      searchTerm: '',
    }
  },
  computed: {
    isLoadingActive: {
      get() {
        return this.isLoading;
      },
      set(val) {
        return val
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'primary',
        Professional : 'success',
        Rejected     : 'danger',
        Resigned     : 'warning',
        Applied      : 'info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    advanceSearch(val) {
      this.searchTerm = val
    }
  },
  methods: {
    setAvatar(value) {
      if (value != null && value.avatar != null) {
        return `${process.env.VUE_APP_URL_API}/avatar/${value.avatar}`
      } 
      return `${process.env.VUE_APP_URL_API}/avatar/logo-default.png`
    },
    getStatus(value) {
      const statusColor = {
        /* eslint-disable key-spacing */
        Activado      : 'success',
        Desactivado : 'danger',
        Bloqueado     : 'danger',
        Pendiente     : 'warning',
        Revisando      : 'warning',
        Rechazado      : 'danger',
        Aprobado      : 'success',
        Archivado      : 'warning',
        Eliminado      : 'danger',
        /* eslint-enable key-spacing */
      }
      return statusColor[value]
    },
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(value)
    },
    selectionChanged(params) {
      this.$emit('selection-changed', params)
    },
    Edit(val) {
      this.$emit('edit', val)
    },
    Delete(val) {
      this.$emit('delete-register', val)
    },
    DeleteBack(val) {
      this.$emit('delete-register-back', val)
    },
    DeleteAll(val) {
      this.$emit('delete-all', val)
    },
    DeleteAllBack(val) {
      this.$emit('delete-all-back', val)
    },
    Detail(val) {
      this.$emit('detail-register', val)
    },
    ActivateCourse(val) {
      this.$emit('activate-course', val)
    },
    ActivateFamily(val) {
      this.$emit('activate-family', val)
    },
    OpcionContrato(val) {
      this.$emit('opcion-contrato', val)
    },
    SendNotify(val) {
      this.$emit('send-notify', val)
    },
    ActivateDocument(val) {
      this.$emit('activate-document', val)
    },
    ShowArchive(val) {
      this.$emit('show-archive', val)
    },
    ShowArchiveDocument(val) {
      this.$emit('show-archive-document', val)
    },
    ShowArchiveDocumentTraducido(val) {
      this.$emit('show-archive-traducido', val)
    },
    Exportar() {
      this.$emit('Exportar')
    },
    ActivateCourseAll() {
      this.$emit('activate-course-all')
    },
    ActivateCourseAllDeactivate() {
      this.$emit('activate-course-all-deactivate')
    },
    SendNotifyAll() {
      this.$emit('SendNotifyAll')
    },
    DocumentShow() {
      this.$emit('document-show')
    },
    Crear() {
      this.$emit('Crear')
    },
    ActivateDocumentSelect() {
      this.$emit('activate-document-select')
    },
    SearchAvanced() {
      this.$emit('search-avanced')
    },
    Edit_one() {
      this.$emit('Edit_one')
    },
    SendMessage() {
      this.$emit('send-message')
    },
    activeRegister(val) {
      this.$emit('active-register', val)
    },
    activeCourse(val) {
      this.$emit('active-course', val)
    },
    activeResponsable(val) {
      this.$emit('active-responsable', val)
    },
    translatorRegister(val) {
      this.$emit('translator-register', val)
    },
    ChangeStatusDocumentSelect() {
      this.$emit('change-status-document-select')
    },
    ChangeStatusDocumentTraductorSelect() {
      this.$emit('change-status-document-traducido-select')
    },
    /* eslint-disable */
  },
}
</script>
<style lang="scss">
.mr-05 {
  margin-right: 3px;
}
.b-b {
  border-top: 1px solid #ebe9f1;
  border-left: 1px solid #ebe9f1;
  border-right: 1px solid #ebe9f1;
}
.mb-02 {
  margin-bottom: 3px;
}
.display-1{
  display: flex;
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>