<template>
  <b-row>
    <b-col cols="12">
      <good-table
        :columns="columns"
        :rows="rows"
        :select-row="true"
        title-table="Listado de roles"
        :is_active_create="true"
        :is_active_edit="true"
        :is_active_trash="true"
        :is_active_trash_all="true"
        @Crear="Crear"
        @Edit_one="Edit_one"
        @edit="Edit"
        @delete-register="deleteRegister"
        @delete-all="deleteAll"
        @selection-changed="selectionChanged"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GoodTable from '@/components/table/Index.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    GoodTable,
  },
  data() {
    return {
      rows: [],
      selectRow: null,
      selecction: [],
      columns: [
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
      ],
    }
  },
  created() {
    this.getRoles()
  },
  methods: {
    getRoles() {
      this.$http.get('/v1/roles/')
        .then(res => { this.rows = res.data })
    },
    Crear() {
      this.$router.push({
        name: 'roles-permisos-create',
      })
    },
    Edit(val) {
      this.$router.push({
        name: 'roles-permisos-edit',
        params: {
          id: val.id,
        },
      })
    },
    Edit_one() {
      if (this.selecction.length > 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
        return this.selecction.length
      }
      this.$router.push({
        name: 'roles-permisos-edit',
        params: {
          id: 1,
        },
      })
      return this.selecction.length
    },
    deleteRegister(val) {
      this.$http.delete(`/v1/roles/${val.id}`).then(res => {//eslint-disable-line
        this.getRoles()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El rol se ha elimanado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton elimanar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/roles/all/delete', {
          id_rol: this.selecction,
        }).then(res => {//eslint-disable-line
          this.getRoles()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'El rol se ha elimanado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
