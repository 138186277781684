<template>
  <vue-index-rol />
</template>
<script>

import index from '@/components/rol/Index.vue'

export default {
  components: {
    'vue-index-rol': index,
  },
}
</script>
